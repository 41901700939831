import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import useSWR from "swr";
import * as Scroll from "react-scroll";
import { Element } from "react-scroll";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Row,
  Spacing,
  Title,
  Text,
  Description,
  SearchField,
  Footer,
} from "../../components";

import logo from "../../assets/logos/portal-de-saude-logo.png";
import banner from "../../assets/site-banner-background.png";
import productImage from "../../assets/woman-health-product-image.png";
import serviceVaccine from "../../assets/services/vaccine.png";
import serviceDNA from "../../assets/services/dna.png";
import serviceVirus from "../../assets/services/virus.png";
import serviceStethoscope from "../../assets/services/stethoscope.png";
import serviceHeart from "../../assets/services/heart.png";
import serviceHeartRate from "../../assets/services/laptop.png";
import serviceMentalHealth from "../../assets/services/mental-health.png";
import serviceInsurance from "../../assets/services/medical-case.png";
import arrowIcon from "../../assets/icons/icon-arrow-white.png";
import arrowIconBlue from "../../assets/icons/icon-arrow-right-blue.png";
import doctor from "../../assets/image-home-page-doctor-mobile.png";

const fetcher = (url) => axios.get(url).then((res) => res.data);
const ScrollLink = Scroll.Link;

function Home() {
  const navigate = useNavigate();
  const FAQMocked = [
    {
      title: "O que é a Conexxa Saúde?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed consectetur dolor. In ex nibh, lacinia in rhoncus vel, pulvinar id ante. Quisque nulla tortor, luctus vitae tincidunt sed, semper id justo. Proin facilisis neque felis, sit amet tristique orci ornare et. Sed eu pellentesque lacus. Aliquam eu rutrum odio, ac euismod mi. Sed quis arcu a est luctus auctor. Aliquam vitae lacus non est feugiat venenatis.",
    },
    {
      title: "Onde são realizados os procedimentos?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed consectetur dolor. In ex nibh, lacinia in rhoncus vel, pulvinar id ante. Quisque nulla tortor, luctus vitae tincidunt sed, semper id justo. Proin facilisis neque felis, sit amet tristique orci ornare et. Sed eu pellentesque lacus. Aliquam eu rutrum odio, ac euismod mi. Sed quis arcu a est luctus auctor. Aliquam vitae lacus non est feugiat venenatis.",
    },
    {
      title: "Os pacotes tem prazo de validade?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed consectetur dolor. In ex nibh, lacinia in rhoncus vel, pulvinar id ante. Quisque nulla tortor, luctus vitae tincidunt sed, semper id justo. Proin facilisis neque felis, sit amet tristique orci ornare et. Sed eu pellentesque lacus. Aliquam eu rutrum odio, ac euismod mi. Sed quis arcu a est luctus auctor. Aliquam vitae lacus non est feugiat venenatis.",
    },
  ];
  const { data } = useSWR(
    `${process.env.REACT_APP_LINK_API}/package-categories`,
    fetcher
  );
  const [totalValue, setTotalValue] = useState(0);
  const [products, setProducts] = useState([]);
  const [city, setCity] = useState("Porto Alegre");
  const mock = [
    {
      id: 1,
      image: productImage,
      title: "Saúde Mulher - Basic",
      description:
        "Uma série de exames e procedimentos médicos visando atender exclusivamente a saúde e o bem estar da mulher.",
      productValue: 149.99,
      totalValue: 149.99,
    },
    {
      id: 2,
      image: productImage,
      title: "Saúde Mulher - Basic",
      description:
        "Uma série de exames e procedimentos médicos visando atender exclusivamente a saúde e o bem estar da mulher.",
      productValue: 149.99,
      totalValue: 149.99,
    },
    {
      id: 3,
      image: productImage,
      title: "Saúde Mulher - Basic",
      description:
        "Uma série de exames e procedimentos médicos visando atender exclusivamente a saúde e o bem estar da mulher.",
      productValue: 149.99,
      totalValue: 149.99,
    },
  ];

  useEffect(() => {
    let amount: any = 0;
    mock.map((elem) => {
      amount = amount + elem.productValue;
    });
    setProducts(mock);
    setTotalValue(amount);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <img src={logo} alt="logo conexx saúde" draggable="false" />
        <ul>
          <ScrollLink
            to="packages-section"
            spy={true}
            smooth={true}
            duration={500}
          >
            <li>Planos e Serviços</li>
          </ScrollLink>
          <ScrollLink
            to="services-section"
            spy={true}
            smooth={true}
            duration={500}
          >
            <li>Procedimentos</li>
          </ScrollLink>
          <ScrollLink
            to="partners-section"
            spy={true}
            smooth={true}
            duration={500}
          >
            <li>Parceiros</li>
          </ScrollLink>
          <ScrollLink to="faq-section" spy={true} smooth={true} duration={500}>
            <li>Perguntas Frequentes</li>
          </ScrollLink>
        </ul>
        <p></p>
      </div>
      <SearchField city={city} />
      <div className={styles.banner}>
        <img
          src={banner}
          className={styles.bannerBackground}
          draggable="false"
        />
        <div className={styles.bannerContent}>
          <img src={doctor} className={styles.doctorImage} alt="" />
          <h1>Cuide da sua saúde com os melhores profissionais</h1>
          <h5>
            Conectamos você aos melhores serviços de saúde e bem-estar do
            mercado
          </h5>
          <ScrollLink
            to="packages-section"
            spy={true}
            smooth={true}
            duration={500}
          >
            <Button text="Planos e Serviços" width="180px">
              <img src={arrowIcon} alt="" />
            </Button>
          </ScrollLink>
        </div>
      </div>
      <Element id="packages-section" name="packages-section">
        <div className={styles.packages}>
          <div className={styles.packagesTitle}>
            <h1>Serviços para sua saúde</h1>
          </div>
          <Spacing size="xxx-large" />
          <div className={styles.packagesRow}>
            {data &&
              data.categories.map((elem) => (
                <div className={styles.package}>
                  <div className={styles.packageBanner}>
                    <img
                      src={`https://conexx.s3.amazonaws.com/${elem.image}`}
                      draggable="false"
                      className={styles.packageBannerImage}
                    />
                  </div>
                  <div className={styles.packageContent}>
                    <Spacing size="x-large" />
                    <Row position="start">
                      <Title bolder={true} appearance="default">
                        {elem.name}
                      </Title>
                    </Row>
                    <Spacing size="medium" />
                    <Text appearance="grey" width="100%" position="start">
                      {elem.description}
                    </Text>
                  </div>
                  <div className={styles.packageFooter}>
                    <Button
                      text="Explorar"
                      appearance="ghost"
                      width="150px"
                      onClick={() => navigate(`/produto/${elem.id}`)}
                    >
                      <img src={arrowIconBlue} alt="" />
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Element>
      <Element id="services-section" name="services-section">
        <div className={styles.services}>
          <div className={styles.servicesHeader}>
            <h1>Nossos serviços e procedimentos</h1>
          </div>
          <Spacing size="large" />
          <div className={styles.servicesContent}>
            <div className={styles.servicesRow}>
              <div className={styles.service}>
                <div className={styles.serviceImage}>
                  <img src={serviceVaccine} draggable="false" />
                </div>
                <p className={styles.serviceTitle}>Vacinas</p>
                <Description textAlign="start" appearance="grey">
                  Oferecemos uma variedade de vacinas para atender adultos e
                  crianças de todas as idades e necessidades
                </Description>
              </div>
              <div className={styles.service}>
                <div className={styles.serviceImage}>
                  <img src={serviceDNA} draggable="false" />
                </div>
                <p className={styles.serviceTitle}>Testes Genéticos</p>
                <Description textAlign="start" appearance="grey">
                  Avaliações e procedimentos para prevenção de doenças genéticas
                  hereditárias, personalizados para atender às suas necessidades
                  e objetivos de saúde
                </Description>
              </div>
              <div className={styles.service}>
                <div className={styles.serviceImage}>
                  <img src={serviceVirus} draggable="false" />
                </div>
                <p className={styles.serviceTitle}>Covid</p>
                <Description textAlign="start" appearance="grey">
                  Oferecemos procedimentos e cuidados especializados para ajudar
                  na prevenção e recuperação pós-Covid, com o objetivo de
                  ajudá-lo a alcançar uma recuperação saudável e completa
                </Description>
              </div>
              <div className={styles.service}>
                <div className={styles.serviceImage}>
                  <img src={serviceStethoscope} draggable="false" />
                </div>
                <p className={styles.serviceTitle}>Acompanhamento médico</p>
                <Description textAlign="start" appearance="grey">
                  Conte com um profissional especializado para cuidar da sua
                  saúde de forma personalizada e atenciosa, garantindo um
                  acompanhamento constante e de qualidade
                </Description>
              </div>
            </div>
            <Spacing size="xx-large" />
            <div className={styles.servicesRow}>
              <div className={styles.service}>
                <div className={styles.serviceImage}>
                  <img src={serviceHeart} />
                </div>
                <p className={styles.serviceTitle}>Exames</p>
                <Description textAlign="start" appearance="grey">
                  Oferecemos uma ampla variedade de exames em clínicas e
                  laboratórios com preços acessíveis, para que você possa cuidar
                  da sua saúde de forma completa
                </Description>
              </div>
              <div className={styles.service}>
                <div className={styles.serviceImage}>
                  <img src={serviceHeartRate} />
                </div>
                <p className={styles.serviceTitle}>Telemedicina</p>
                <Description textAlign="start" appearance="grey">
                  Realize consultas e diagnósticos médicos sem sair de casa, por
                  meio do nosso atendimento online, e tenha a análise dos
                  resultados dos seus exames de forma prática e conveniente
                </Description>
              </div>
              <div className={styles.service}>
                <div className={styles.serviceImage}>
                  <img src={serviceMentalHealth} />
                </div>
                <p className={styles.serviceTitle}>Saúde Mental</p>
                <Description textAlign="start" appearance="grey">
                  Cuide da sua saúde mental por meio de consultas psicológicas e
                  psiquiátricas especializadas, oferecidas por profissionais
                  capacitados e dedicados ao seu bem-estar emocional
                </Description>
              </div>
              <div className={styles.service}>
                <div className={styles.serviceImage}>
                  <img src={serviceInsurance} />
                </div>
                <p className={styles.serviceTitle}>Check-up</p>
                <Description textAlign="start" appearance="grey">
                  "Realize uma ampla gama de exames e procedimentos médicos
                  especializados, voltados para a prevenção e cuidado com a sua
                  saúde, proporcionando maior segurança e tranquilidade para
                  você e sua família
                </Description>
              </div>
            </div>
          </div>
        </div>
      </Element>
      <Footer />
    </div>
  );
}

export default Home;
