import React from "react";
import styles from "./styles.module.scss";
import { FaLock } from "react-icons/fa";

function Input({
  name,
  type = "text",
  label,
  placeholder,
  value,
  width = "100%",
  max,
  disabled = false,
  icon,
  position = "start",
  maxLength,
  onChange,
  onBlur,
  upperCase = false,
  error = false,
  errorMessage = 'Campo Obrigatório'
}: any) {
  return (
    <div className={styles.field} style={{ maxWidth: width && width }}>
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        max={max}
        autoComplete="off"
        disabled={disabled}
        maxLength={maxLength}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={onBlur}
        className={`
          ${value ? styles.labelValueOn : ""} 
          ${disabled ? styles.disabled : ""}
          ${position === "start" ? styles.start : ""}
          ${position === "center" ? styles.center : ""}
          ${position === "end" ? styles.end : ""}
        `}
        style={{
          border: error ? "2px solid red" : "",
          textTransform: upperCase ? "uppercase" : "none",
        }}
      />
      {icon && <div className={styles.icon}>{icon}</div>}
      {disabled && (
        <div className={styles.icon}>
          <FaLock />
        </div>
      )}
      {error && <i>{errorMessage}</i>}
    </div>
  );
}

export default Input;
