import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { maskMoney } from "../../utils/mask";
import {
  Layout,
  Spacing,
  Title,
  Column,
  Row,
  Button,
  Text,
  Card,
  Undertitle,
} from "../../components";
import ExamCard from "../Payment/components/ExamCard";

function PaymentBeneficiaries() {
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState(0);
  const [packages, setPackages] = useState<any>([]);

  useEffect(() => {
    handleTotalValue();
    const payment = JSON.parse(sessionStorage.getItem("@payment"));
    if (payment) {
      setPackages(payment);
    } else {
      const products = [];
      const data = JSON.parse(localStorage.getItem("@data"));
      data.products.map((product) => {
        if (product.amount > 1) {
          const items = [];
          for (let index = 1; index <= product.amount; index++) {
            items.push({
              ...product,
              id: `${product.name}-${index}`,
              form: {
                name: "",
                document: "",
                birth_date: "",
                email: "",
                state: "",
                city: "",
                allPackagesToSameUser: false,
              },
            });
          }
          products.push({
            id: product.id,
            title: product.name,
            value: product.value,
            products: items,
          });
        } else {
          products.push({
            id: product.id,
            title: product.name,
            value: product.value,
            products: [
              {
                ...product,
                id: `${product.name}-1`,
                form: {
                  name: "",
                  document: "",
                  birth_date: "",
                  email: "",
                  state: "",
                  city: "",
                  allPackagesToSameUser: false,
                },
              },
            ],
          });
        }
      });
      setPackages(products);
    }
  }, []);

  function handleTotalValue() {
    let amount: any = 0;
    const storage = JSON.parse(localStorage.getItem("@data"));
    if (storage) {
      storage.products.map((elem) => {
        amount = amount + parseFloat(elem.value) * elem.amount;
      });
      setTotalValue(amount);
    }
  }

  function duplicateFormToAll(form: any) {
    packages.map((elem: any) => {
      elem.products.map((product: any) => {
        product.form = form;
      });
    });
    setPackages([...packages]);
    sessionStorage.setItem("@payment", JSON.stringify(packages));
  }

  return (
    <Layout navbar={true} width="90%">
      <Spacing size="xxx-large" />
      <div className={styles.content}>
        <Column width="40%" position="start">
          <Title>Dados do paciente</Title>
          <Spacing size="medium" />
          {packages &&
            packages.length > 0 &&
            packages.map((elem: any) => {
              return (
                <>
                  <Undertitle appearance="primary-main" bolder={true}>
                    {elem.title}
                  </Undertitle>
                  {elem.products.map((product: any) => {
                    return (
                      <ExamCard
                        id={product.id}
                        title={product.name}
                        amount={product.value}
                        data={product}
                        onSendForm={(event) => {
                          product.form = event;
                          sessionStorage.setItem(
                            "@payment",
                            JSON.stringify(packages)
                          );
                        }}
                        onSendFormToAll={(event) => {
                          duplicateFormToAll(event);
                        }}
                      />
                    );
                  })}
                  <Spacing size="small" />
                </>
              );
            })}
        </Column>
        <Spacing size="xxx-large" />
        <Column width="40%">
          <Title>Resumo da Compra</Title>
          <Spacing size="medium" />
          <Card appearance="ghost" width="97%">
            <Row position="centered">
              <Column position="start">
                <Text position="start" bolder={true}>
                  Subtotal
                </Text>
              </Column>
              <Column position="end">
                <Text position="start">R$ {maskMoney(totalValue)}</Text>
              </Column>
            </Row>
            <Spacing size="xx-large" />
            <Row position="centered">
              <Column position="start">
                <Text position="start">Desconto</Text>
              </Column>
              <Column position="end">
                <Text position="start">R$ 0,00</Text>
              </Column>
            </Row>
            <Spacing size="xx-large" />
            <Row position="centered">
              <Column position="start">
                <Title position="start" appearance="primary" bolder={true}>
                  Total a pagar
                </Title>
              </Column>
              <Column position="end">
                <Title position="start" bolder={true}>
                  R$ {maskMoney(totalValue)}
                </Title>
              </Column>
            </Row>
          </Card>
          <Spacing size="medium" />
          <Button
            text="Continuar a Compra"
            width="100%"
            appearance="primary"
            size="lg"
            onClick={() => {
              navigate("/pagamento");
            }}
          />
        </Column>
      </div>
    </Layout>
  );
}

export default PaymentBeneficiaries;
