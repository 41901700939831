import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { maskCPF, maskMoney } from "../../utils/mask";
import {
  Layout,
  Spacing,
  Title,
  Column,
  Pix,
  Billet,
  CreditCard,
  Input,
  Row,
  Button,
  Undertitle,
  Card,
  Text
} from "../../components";
import ExamCard from "./components/ExamCard";

function Payment() {
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState(0);
  const [packages, setPackages] = useState<any>([]);
  const [checks, setChecks] = useState([
    {
      id: 1,
      status: false,
    },
    {
      id: 2,
      status: false,
    },
    {
      id: 3,
      status: true,
    },
  ]);

  useEffect(() => {
    handleTotalValue();
    const products = [];
    const data = JSON.parse(localStorage.getItem("@data"));
    console.log(data.products);
    data.products.map((product) => {
      if (product.amount > 1) {
        const items = [];
        for (let index = 1; index <= product.amount; index++) {
          items.push({
            ...product,
            id: `${product.name}-${index}`,
            form: {
              name: "",
              document: "",
              birth_date: "",
              email: "",
              state: "",
              city: "",
              allPackagesToSameUser: false,
            },
          });
        }
        products.push({
          id: product.id,
          title: product.name,
          value: product.value,
          products: items,
        });
      } else {
        products.push({
          id: product.id,
          title: product.name,
          value: product.value,
          products: [
            {
              ...product,
              id: `${product.name}-1`,
              form: {
                name: "",
                document: "",
                birth_date: "",
                email: "",
                state: "",
                city: "",
                allPackagesToSameUser: false,
              },
            },
          ],
        });
      }
    });
    console.log("products: ", products);
    setPackages(products);
  }, []);

  function handleChangeCheck(id: number) {
    checks.map((check) => {
      check.status = false;
    });
    let obj = checks.find((o) => o.id === id);
    obj.status = true;
    setChecks([...checks]);
  }

  function handleTotalValue() {
    let amount: any = 0;
    const storage = JSON.parse(localStorage.getItem("@data"));
    if (storage) {
      storage.products.map((elem) => {
        amount = amount + parseFloat(elem.value) * elem.amount;
      });
      setTotalValue(amount);
    }
  }

  function handlePix() {
    const formData = new FormData();
    const storage = JSON.parse(localStorage.getItem("@data"));
    formData.append("purchase_id", storage.info.purchase_id);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_LINK_API}/packages/payment/create/pix`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      window.open(response.data.payment.pix_link, "_blank", "noreferrer");

      axios({
        method: "post",
        url: `${process.env.REACT_APP_LINK_API}/purchasesuccess/${storage.info.purchase_id}`,
        data: {},
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {});
    });
  }

  function handleBillet() {
    const formData = new FormData();
    const storage = JSON.parse(localStorage.getItem("@data"));
    formData.append("purchase_id", storage.info.purchase_id);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_LINK_API}/packages/payment/create/billet`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      window.open(response.data.payment.ticket_link, "_blank", "noreferrer");

      axios({
        method: "post",
        url: `${process.env.REACT_APP_LINK_API}/purchasesuccess/${storage.info.purchase_id}`,
        data: {},
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {});
    });
  }

  function duplicateFormToAll(form: any) {
    packages.map((elem: any) => {
      elem.products.map((product: any) => {
        product.form = form;
      });
    });
    setPackages([...packages]);
  }

  function handleForm() {
    navigate("/pagamento/sucesso");
  }

  return (
    <Layout navbar={true} width="90%">
      <Spacing size="xxx-large" />
      <div className={styles.content}>
        <Column width="40%">
          <Title>Método de pagamento</Title>
          <Spacing size="medium" />
          <Pix
            check={checks[0].status}
            onChangeCheckbox={() => handleChangeCheck(checks[0].id)}
            onClick={handlePix}
          />
          <Spacing size="xx-small" />
          <Billet
            check={checks[1].status}
            onChangeCheckbox={() => handleChangeCheck(checks[1].id)}
            onClick={handleBillet}
          />
          <Spacing size="xx-small" />
          <CreditCard
            check={checks[2].status}
            onChangeCheckbox={() => handleChangeCheck(checks[2].id)}
          />
        </Column>
        <Spacing size="xxx-large" />
        <Column width="40%">
          <Title>Resumo da Compra</Title>
          <Spacing size="medium" />
          <Card appearance="ghost" width="97%">
            <Row position="centered">
              <Column position="start">
                <Text position="start" bolder={true}>
                Subtotal
                </Text>
              </Column>
              <Column position="end">
                <Text position="start">
                  R$ {maskMoney(totalValue)}
                </Text>
              </Column>
            </Row>
          <Spacing size="xx-large" />
            <Row position="centered">
              <Column position="start">
                <Text position="start">
                  Desconto
                </Text>
              </Column>
              <Column position="end">
                <Text position="start">
                  R$ 0,00
                </Text>
              </Column>
            </Row>
          <Spacing size="xx-large" />
            <Row position="centered">
              <Column position="start">
                <Title position="start" appearance="primary" bolder={true}>
                  Total a pagar
                </Title>
              </Column>
              <Column position="end">
                <Title position="start" bolder={true}>
                  R$ {maskMoney(totalValue)}
                </Title>
              </Column>
            </Row>
          </Card>
          <Spacing size="medium" />
          <Button
            text="Realizar pagamento"
            width="100%"
            appearance="primary"
            size="lg"
            onClick={() => handleForm()}
          />
        </Column>
      </div>
    </Layout>
  );
}

export default Payment;

