import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { maskCep, maskCPF, maskPhone } from "../../utils/mask";
import { FaMapMarkerAlt } from "react-icons/fa";
import Spinner from "../../components/Spinner";
import {
  Card,
  Layout,
  Row,
  Title,
  Text,
  Spacing,
  Input,
  Button,
  Modal,
  BottomNavigation,
  Column,
} from "../../components";

import error from "../../assets/icons/error-unenabled-location-icon.png";
import arrow from "../../assets/icons/icon-arrow-right-white.png";

function Registration() {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [cep, setCep] = useState("");
  const [checkEInputDoc, setCheckInputDoc] = useState(false);
  const [checkEInputPhone, setCheckInputPhone] = useState(false);
  const [checkEInputEmail, setCheckInputEmail] = useState(false);
  const [form, setForm] = useState({
    document: "",
    phone: "",
    email: "",
  });
  const { updateOrderID }: any = useContext(DataContext);

  function handleRegistration() {
    if (!loading) {
      setLoading(true);
      const formData = new FormData();
      formData.append("cpf", form.document);
      formData.append("phone", form.phone);
      formData.append("email", form.email);
      formData.append("total", "150");
      formData.append("partner_slug", "starta");

      axios({
        method: "post",
        url: `${process.env.REACT_APP_LINK_API}/packages/purchase`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        updateOrderID({ purchase_id: response.data.purchase.id });
        setShowSpinner(true);
        setTimeout(() => {
          setShowSpinner(false);
          navigate("/autenticacao");
          setLoading(false);
        }, 4000);
      });
    }
  }
  
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    });
  }, []);

  function checkForm() {
    if (form.document === "" || form.document.length < 10) {
      setCheckInputDoc(true);
    } else {
      setCheckInputDoc(false);
    }
    if (form.phone === "" || form.phone.length < 14) {
      setCheckInputPhone(true);
    } else {
      setCheckInputPhone(false);
    }
    if (form.email === "") {
      setCheckInputEmail(true);
    }
    if (
      form.document !== "" &&
      form.phone !== "" &&
      form.email !== "" &&
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)
    ) {
      handleRegistration();
    }
  }

  return (
    <Layout navbar={true} verticalCentered={true}>
      <Modal
        isOpen={modalIsOpen}
        title="Informe o CEP da sua região"
        description="Iremos filtrar os serviços de saúde associados a
        Conexxa disponíveis na sua região."
        onClickClose={() => setModalIsOpen(false)}
      >
        <Spacing size="large" />
        <Input
          name="cep"
          type="text"
          width="100%"
          maxLength="10"
          placeholder=""
          value={cep}
          onChange={(event) => {
            setCep(maskCep(event.target.value));
          }}
        />
        <Spacing size="large" />
        <BottomNavigation
          width="74px"
          labelLeft="Cancelar"
          appearanceLeft="ghost"
          actionLeft={() => setModalIsOpen(false)}
          labelRight="Continuar"
          appearanceRight="primary"
          actionRight={() => {
            setModalIsOpen(false);
            setModalErrorIsOpen(true);
          }}
        />
      </Modal>
      <Modal
        isOpen={modalErrorIsOpen}
        image={error}
        imageWidth="70px"
        title="Serviço indisponível em sua região"
        description="O pacote Saúde da Mulher Premium não está disponível para sua região. Mude a sua localização ou selecione um dos serviços disponíveis da sua região"
        onClickClose={() => setModalErrorIsOpen(false)}
        footerButtonFirst="Ver serviços disponíveis"
        onClickFooterFirst={() => setModalErrorIsOpen(false)}
        footerButtonSecond="Mudar localização"
        footerButtonSecondIcon={<FaMapMarkerAlt />}
        footerButtonSecondIconPosition="left"
        onClickFooterSecond={() => setModalErrorIsOpen(false)}
      ></Modal>

      {showSpinner ? (
        <Spinner />
      ) : (
        <Card appearance="ghost" width="530px">
          <div className={styles.header}>
            <Title bolder={true} appearance="grey">
              Olá, como vai?
            </Title>
            <Spacing size="x-small" />
            <Text maxWidth="370px">
              Preencha os campos abaixo, para que possamos dar continuidade ao
              pagamento
            </Text>
          </div>
          <Spacing size="xxx-large" />
          <div className={styles.content}>
            <div className={styles.contentRow}>
              <div className={styles.contentColumn}>
                <label htmlFor="document">CPF</label>
                <Input
                  name="document"
                  type="text"
                  width="100%"
                  maxLength="14"
                  placeholder="Somente Números"
                  error={checkEInputDoc}
                  value={maskCPF(form.document)}
                  onChange={(event) => {
                    setForm({ ...form, document: event.target.value });
                    if (form.document !== "") {
                      setCheckInputDoc(false);
                    }
                  }}
                />
              </div>
              <div className={styles.contentColumn}>
                <label htmlFor="phone">Telefone</label>
                <Input
                  name="phone"
                  type="text"
                  width="100%"
                  maxLength="15"
                  placeholder="DDD+ seu  Telefone"
                  error={checkEInputPhone}
                  value={maskPhone(form.phone)}
                  onChange={(event) => {
                    setForm({ ...form, phone: event.target.value });
                    if (form.phone !== "") {
                      setCheckInputPhone(false);
                    }
                  }}
                />
              </div>
            </div>
            <Spacing size="medium" />
            <Row width="100%" position="start">
              <Column>
                <label htmlFor="email">E-mail</label>
                <Input
                  name="email"
                  type="text"
                  width="100%"
                  maxLength="256"
                  placeholder="Informe seu e-mail"
                  value={form.email}
                  error={checkEInputEmail}
                  onChange={(event) => {
                    if (
                      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
                        event.target.value
                      )
                    ) {
                      setCheckInputEmail(false);
                    } else {
                      setCheckInputEmail(true);
                    }
                    setForm({ ...form, email: event.target.value });
                  }}
                />
              </Column>
            </Row>
          </div>
          <Spacing size="xxx-large"></Spacing>
          <div className={styles.cardFooter}>
            <Button
              text="Continuar"
              loading={loading}
              width="150px"
              appearance={loading ? "disabled" : "primary"}
              onClick={checkForm}
            >
              <img src={arrow} alt="" />
            </Button>
            <Spacing size="xx-small" />
          </div>
        </Card>
      )}
    </Layout>
  );
}

export default Registration;
