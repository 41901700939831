import React from "react";
import styles from "./styles.module.scss";
import { Row } from "..";

import tik from '../../assets/icons/tik-poin.png';
import untik from '../../assets/icons/untik-point.png';

function Checkbox({ label, checked, onChange }: any) {
  return (
    <Row position="start">
      {
        checked ? 
        <img src={tik} alt="" onClick={onChange} style={{ cursor: 'pointer' }} draggable="false" /> 
        : 
        <img src={untik} alt="" onClick={onChange}  style={{ cursor: 'pointer' }} draggable="false"/>
      }
      <p className={styles.title}>{label}</p>
    </Row>
  );
}

export default Checkbox;
