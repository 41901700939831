import React from "react";
import { Description, Text, Spacing } from "..";

function LabelledValue({ label, value }: any) {
  return (
    <>
      <Description appearance="grey">{label}</Description>
      <Spacing size="xxx-small" />
      <Text bolder={true}>{value}</Text>
    </>
  );
}

export default LabelledValue;
