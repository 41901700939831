import React from "react";
import styles from "./styles.module.scss";
import { FaChevronLeft } from "react-icons/fa";
import { Navbar, Row, Button, Spacing, Steps, Footer } from "..";

function Layout({
  children,
  verticalCentered = false,
  navbar = false,
  steps,
  goBackButton = false,
  onClick,
  width = "950px",
}: any) {
  return (
    <>
      {navbar && <Navbar />}
      <div className={styles.container} style={{ maxWidth: width }}>
        {steps && (
          <>
            <Spacing size="medium" />
            <Row position="centered">
              <Steps steps={steps} />
            </Row>
          </>
        )}
        {goBackButton && (
          <>
            <Spacing size="medium" />
            <Row position="start">
              <Button
                width="188px"
                text="Voltar para o Painel"
                appearance="ghost"
                iconPosition="left"
                onClick={onClick}
              >
                <FaChevronLeft />
              </Button>
            </Row>
          </>
        )}
        <Spacing size="small" />
        <div
          className={styles.content}
          style={{ justifyContent: verticalCentered ? "center" : "start" }}
        >
          {children}
        </div>
        <Spacing size="x-large" />
      </div>
      <Footer />
    </>
  );
}

export default Layout;
