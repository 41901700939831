import React from "react";
import styles from "./styles.module.scss";
import { BsXLg } from "react-icons/bs";
import { Title, Description, Spacing, Button } from "..";

function Modal({
  isOpen = false,
  width = "500px",
  height,
  image,
  imageWidth = "80%",
  title,
  description,
  children,
  onClickClose,
  footerPosition = "space-between",
  footerButtonFirst,
  footerButtonFirstAppearance,
  footerButtonFirstWidth,
  onClickFooterFirst,
  footerButtonSecond,
  footerButtonSecondAppearance,
  footerButtonSecondWidth,
  footerButtonSecondIcon,
  footerButtonSecondIconPosition,
  onClickFooterSecond,
}: any) {
  return (
    <>
      {isOpen && (
        <div className={styles.container}>
          <div
            className={styles.modal}
            style={{ width: width && width, height: height && height }}
          >
            <div className={styles.header}>
              {image && (
                <img
                  src={image}
                  alt={title}
                  style={{ width: imageWidth && imageWidth }}
                  className={styles.image}
                />
              )}
              <Spacing size="medium" />
              <Title bolder={true} appearance="primary">
                {title}
              </Title>
              <Spacing size="xx-small" />
              <div className={styles.description}>
                <Description>{description}</Description>
              </div>
              {onClickClose && (
                <BsXLg className={styles.close} onClick={onClickClose} />
              )}
            </div>
            <Spacing size="xxx-large" />
            <div className={styles.body}>{children}</div>
            <div
              className={styles.footer}
              style={{ justifyContent: footerPosition }}
            >
              {footerButtonFirst && (
                <>
                  <Button
                    appearance={footerButtonFirstAppearance}
                    text={footerButtonFirst}
                    width={footerButtonFirstWidth}
                    onClick={onClickFooterFirst}
                  />
                </>
              )}
              {footerButtonSecond && (
                <>
                  <Button
                    appearance={footerButtonSecondAppearance}
                    text={footerButtonSecond}
                    onClick={onClickFooterSecond}
                    width={footerButtonSecondWidth}
                    iconPosition={footerButtonSecondIconPosition}
                  >
                    {footerButtonSecondIcon}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
