import React from "react";
import styles from "./styles.module.scss";
import { Card, Row, Column, Description } from "..";

function Table({ columns, children }: any) {
  return (
    <Card appearance="ghost" width="100%" padding="0px">
      <div className={styles.header}>
        <Row>
          {columns.map((elem) => (
            <Column>
              <Description appearance="grey">{elem}</Description>
            </Column>
          ))}
        </Row>
      </div>
      <div className={styles.body}>{children}</div>
    </Card>
  );
}

export default Table;
