import React from "react";
import styles from "./styles.module.scss";

function Card({
  appearance = "default",
  width = "400px",
  padding = "24px",
  textAlign = "center",
  children,
}: any) {
  return (
    <div
      className={`${styles.container} 
        ${appearance === "default" && styles.default} 
        ${appearance === "primary" && styles.primary} 
        ${appearance === "complementary" && styles.complementary}
        ${appearance === "ghost" && styles.ghost}
        ${appearance === "success" && styles.success}
        ${appearance === "warning" && styles.warning}
        ${appearance === "error" && styles.error}
        `}
      style={{
        maxWidth: width && width,
        padding: padding && padding,
        textAlign: textAlign && textAlign,
      }}
    >
      {children}
    </div>
  );
}

export default Card;
