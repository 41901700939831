import React from "react";
import styles from "./styles.module.scss";
import { FaDownload, FaPrint } from "react-icons/fa";
import { Row, Column, Button, Description, Spacing } from "..";

function PDFViewer({ url, protocol, children }: any) {
  function handlePrint(event) {
    event.preventDefault();
    window.open(url, "PRINT", "height=400,width=600");
  }

  return (
    <div className={styles.container}>
      <Row>
        <Column>
          <Description padding="0px 10px">
            Número do protocolo {protocol}
          </Description>
        </Column>
        <Column position="end">
          <Row position="end">
            <a
              id="downloadLink"
              href={url}
              target="_blank"
              type="application/octet-stream"
              download="yourpdf.pdf"
            >
              <Button text="Download" width="100px" appearance="ghost">
                <FaDownload />
              </Button>
            </a>
            <Button
              text="Imprimir"
              width="120px"
              appearance="ghost"
              onClick={(event: any) => handlePrint(event)}
            >
              <FaPrint />
            </Button>
            {children}
          </Row>
        </Column>
      </Row>
      <Spacing size="small" />
      <Row>
        <object data={url} type="text/html" className={styles.viewer}>
          <p>Alternative text - include a link </p>
          <param name="data" value={url}></param>
        </object>
      </Row>
    </div>
  );
}

export default PDFViewer;
