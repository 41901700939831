import React from "react";
import styles from "./styles.module.scss";

function Title({
  children,
  bolder = false,
  pointer = false,
  appearance = "default",
  padding,
}: any) {
  return (
    <h1
      className={`${styles.text} 
      ${appearance === "default" && styles.default} 
      ${appearance === "primary" && styles.primary} 
      ${appearance === "complementary" && styles.complementary}
      ${appearance === "white" && styles.white}
      `}
      style={{
        fontWeight: bolder ? "bolder" : "normal",
        cursor: pointer ? "pointer" : "default",
        padding: padding && padding,
      }}
    >
      {children}
    </h1>
  );
}

export default Title;
