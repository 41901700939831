import React from "react";
import styles from "./styles.module.scss";

function Description({
  children,
  position,
  bolder = false,
  pointer = false,
  appearance = "default",
  padding,
  textAlign = "center",
}: any) {
  return (
    <p
      className={`${styles.text} 
        ${position === "start" && styles.start} 
        ${position === "centered" && styles.centered} 
        ${position === "end" && styles.end} 
        ${appearance === "default" && styles.default} 
        ${appearance === "primary" && styles.primary} 
        ${appearance === "complementary" && styles.complementary}
        ${appearance === "grey" && styles.grey} 
        ${appearance === "white" && styles.white}
      `}
      style={{
        fontWeight: bolder ? "bolder" : "normal",
        cursor: pointer ? "pointer" : "default",
        padding: padding && padding,
        textAlign: textAlign && textAlign,
      }}
    >
      {children}
    </p>
  );
}

export default Description;
