import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Column,
  Text,
  Row,
  Spacing,
  Button,
  SearchField,
  Footer,
  Divider,
  Title,
} from "../../components";
import { FaChevronDown } from "react-icons/fa";

import logo from "../../assets/logos/conexxa-logo.png";
import unimed from "../../assets/logos/unimed-logo-green.png";
import conexxa from "../../assets/logos/conexxa-logo-blue.png";
import check from "../../assets/icons/icon-check-green.png";

function PaymentConcluded() {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const packages = JSON.parse(localStorage.getItem("@data"));
    setPackages(packages);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <img
          src={logo}
          alt="logo conexx saúde"
          draggable="false"
          onClick={() => navigate("/")}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.contentColumn}>
          <h1>Sua compra foi realizada com sucesso!</h1>
          <Spacing size="large"></Spacing>
          <Text>
            Utilize o código abaixo na hora de agendar sua consulta/exame:{" "}
          </Text>
          <Spacing size="large"></Spacing>
          <div className={styles.code}>1202306</div>
          <Spacing size="large"></Spacing>
          <h1>Agendar consultas e Exames</h1>
          <ul>
            <li>
              Para agendar sua consulta ligue para (00) 08000-8800 ou acesso ou
              clique em Agendar Consulta;
            </li>
            <Spacing size="medium"></Spacing>

            <li>
              Informe o código acima no momento do agendamento, junto com a
              documentação solicitada;
            </li>
            <Spacing size="medium"></Spacing>

            <li>
              No dia da consulta, leve seu documento de identificação e a
              requisição do exame, caso haja necessidade.
            </li>
          </ul>
          <Spacing size="large"></Spacing>
          <Button
            text="Agendar Consulta"
            width="300px"
            appearance="primary"
            onClick={() => {
              navigate("/resumo");
            }}
          ></Button>
          <Spacing size="large"></Spacing>
          <h1>Onde realizar o exame?</h1>
          <div className={styles.partners}>
            <img src={unimed} />
          </div>
          <p className={styles.text}>
            Endereço: Rua X, Avenida Y, Centro - Porto Alegre, RS Contato: (00)
            0000-0000 | E-mail: contato@unimed.com.br
          </p>
          <Spacing size="small"></Spacing>
          <h1>Agendar consultas e Exames</h1>
          <ul>
            <li>
              No dia do atendimento é necessário apresentar documento de
              identificação com foto, solicitação original do exame com data,
              carimbo, CID e assinatura médica;
            </li>
            <Spacing size="medium"></Spacing>
            <li>Este exame é realizado somente com solicitação médica;</li>
            <Spacing size="medium"></Spacing>

            <li>
              Paciente menor de 18 anos deverá estar acompanhado de um adulto
              responsável;
            </li>
            <Spacing size="medium"></Spacing>

            <li>
              Tenha foco naquilo que motivou a consulta, descreva objetivamente
              ao médico o que é importante.
            </li>
          </ul>
        </div>
        <div className={`${styles.contentColumn} ${styles.resume}`}>
          <Card appearance="ghost" width="100%">
            <Row>
              <Column>
                <h2 className={styles.title}>Detalhes da Compra</h2>
              </Column>
            </Row>
            <Spacing size="xx-large"></Spacing>
            {packages.map((item: any) => (
              <>
                <Row position="centered">
                  <Column position="start">
                    <Text position="start">{item.name}</Text>
                  </Column>
                  <Column position="end">
                    <Text>R$ {item.value}</Text>
                  </Column>
                </Row>
                <Spacing size="medium"></Spacing>
              </>
            ))}

            <Row position="centered">
              <Column position="start">
                <Text position="start">Saúde da Mulher - Plano Pemium</Text>
              </Column>
              <Column position="end">
                <Text>R$ 512,00</Text>
              </Column>
            </Row>
            <Spacing size="medium"></Spacing>
            <Row position="centered">
              <Column position="start">
                <Text position="start">Saúde da Mulher - Plano Pemium</Text>
              </Column>
              <Column position="end">
                <Text>R$ 512,00</Text>
              </Column>
            </Row>

            <Spacing size="xxx-large"></Spacing>
            <Divider />
            <Spacing size="xxx-large"></Spacing>

            <Row position="centered">
              <Column position="start">
                <Text position="start">Pagamento</Text>
              </Column>
              <Column position="end">
                <Text>2x cartão</Text>
              </Column>
            </Row>

            <Spacing size="xxx-large"></Spacing>
            <Divider />
            <Spacing size="xxx-large"></Spacing>

            <Row position="centered">
              <Column position="start">
                <Title position="start" bolder={true}>
                  Pagamento
                </Title>
              </Column>
              <Column position="end">
                <Title position="start" bolder={true}>
                  R$ 512,00
                </Title>
              </Column>
            </Row>

            <Spacing size="xxx-large"></Spacing>
            <Button
              text="Comprovante em PDF"
              width="94%"
              appearance="primary"
              onClick={() => {
                navigate("/resumo");
              }}
            ></Button>
            <Spacing size="large"></Spacing>
            <Button
              text="Voltar para tela inicial"
              width="94%"
              appearance="ghost"
              onClick={() => {
                navigate("/resumo");
              }}
            ></Button>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PaymentConcluded;
