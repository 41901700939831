import React from "react";
import styles from "./styles.module.scss";

function Column({ width = "100%", position = "start", children }: any) {
  return (
    <div
      className={`
        ${styles.container} 
        ${position === "start" && styles.start} 
        ${position === "centered" && styles.centered} 
        ${position === "end" && styles.end} 
        `}
        style={{width: width && width}}
    >
      {children}
    </div>
  );
}

export default Column;
