import React from "react";
import styles from "./styles.module.scss";
import { Spacing } from "../../../../components";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

function Amount({ amount, addAmount, subsAmount }) {
  return (
    <div className={styles.container}>
      <p
        onClick={subsAmount}
        style={{ color: amount === 1 ? "grey" : "var(--primary-color-main)" }}
        className={styles.controller}
      >
        <AiOutlineMinus />
      </p>
      <Spacing size="x-small" />
      <p className={styles.amount}>{amount}</p>
      <Spacing size="x-small" />
      <p
        onClick={addAmount}
        style={{ color: amount === 13 ? "grey" : "var(--primary-color-main)" }}
        className={styles.controller}
      >
        <AiOutlinePlus />
      </p>
    </div>
  );
}

export default Amount;
