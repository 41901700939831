import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { maskMoney } from "../../utils/mask";
import { DataContext } from "../../context/DataContext";
import Product from "./components/Product";
import {
  Layout,
  Spacing,
  Description,
  Row,
  Divider,
  Modal,
  Input,
  Button,
} from "../../components";

function Summary() {
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState(0);
  const { context, handleData, deleteProduct }: any = useContext(DataContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [coupon, setCoupon] = useState();

  useEffect(() => {
    checkProductsValue();
    handleTotalValue();
  }, []);

  function handleTotalValue() {
    let amount: any = 0;
    const storage = JSON.parse(localStorage.getItem("@data"));
    storage.products.map((elem) => {
      amount = amount + parseFloat(elem.value) * elem.amount;
    });
    setTotalValue(amount);
  }

  function checkProductsValue() {
    context.products.map((product) => {
      axios
        .get(`${process.env.REACT_APP_LINK_API}/packages-id/${product.id}`)
        .then((resp) => {
          // eslint-disable-next-line no-param-reassign
          product.value = resp.data.packages[0].value;
          // eslint-disable-next-line no-param-reassign
          product.totalValue = parseFloat(resp.data.packages[0].value);
        })
        .finally(() => {
          handleData(context);
        });
    });
  }

  function addProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount += 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });

    updateTotalValue();
  }

  function subsProduct(id: number, value: any) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products[index].amount -= 1;
    context.products[index].totalValue = value.toFixed(2);
    handleData({ products: context.products });

    updateTotalValue();
  }

  function updateTotalValue() {
    let amount: any = 0;
    context.products.map((elem) => {
      amount = amount + parseFloat(elem.value) * elem.amount;
    });
    setTotalValue(amount);
  }

  function handlePayment() {
    navigate("/cadastro");
  }

  return (
    <Layout navbar={true} width="90%">
      <Modal
        isOpen={modalIsOpen}
        title="Insira um Cupom"
        description="Cada produto pode ter apenas um cupom aplicado."
        footerButtonFirst="Cancelar"
        footerButtonFirstAppearance="ghost"
        footerButtonFirstWidth="74px"
        onClickFooterFirst={() => setModalIsOpen(false)}
        footerButtonSecond="Aplicar"
        footerButtonSecondAppearance="primary"
        footerButtonSecondWidth="74px"
        onClickFooterSecond={() => setModalIsOpen(false)}
        onClickClose={() => setModalIsOpen(false)}
      >
        <Spacing size="large" />
        <Input
          name="coupon"
          type="text"
          width="100%"
          placeholder="Inserir Código do cupom"
          value={coupon}
          onChange={(event) => {
            setCoupon(event.target.value);
          }}
        />
        <Spacing size="large" />
      </Modal>

      <Spacing size="xx-large" />
      {window.innerWidth > 1000 ? (
        <div className={styles.tableHeader}>
          <div className={`${styles.column} ${styles.start}`}>
            <p>Plano</p>
          </div>
          <div className={styles.column}>
            <p>Preço</p>
          </div>
          <div className={styles.column}>
            <p>Quantidade</p>
          </div>
          <div className={styles.column}>
            <p>Total</p>
          </div>
          <div className={styles.column}></div>
        </div>
      ) : (
        <div className={styles.tableHeader}>
          <div className={`${styles.column}`}>
            <p>Plano</p>
          </div>
          <div className={styles.column}></div>
        </div>
      )}
      <div className={styles.container}>
        {context.products.length > 0 ? (
          context.products.map((product) => (
            <>
              <Spacing size="large" />
              <Product
                image={`https://conexx.s3.amazonaws.com/${product.image}`}
                title={product.name}
                description={product.description}
                productValue={product.value}
                amount={product.amount}
                totalValue={product.totalValue}
                onClickDelete={() => {
                  deleteProduct(product.id);
                  handleTotalValue();
                }}
                addProduct={(event) => addProduct(product.id, event)}
                subsProduct={(event) => subsProduct(product.id, event)}
              />
            </>
          ))
        ) : (
          <>
            <Spacing size="large" />
            <Row>
              <Description appearance="grey">
                Nenhum produto foi adicionado ao carrinho
              </Description>
            </Row>
          </>
        )}
        <Spacing size="medium" />
        <Divider />
        <Spacing size="x-large" />
        <Row position="end">
          <div className={styles.summary}>
            <h1>Detalhes do preço</h1>
            {context.products.length > 0 ? (
              context.products.map((product) => (
                <div className={styles.valueDisplay}>
                  <h5>Preço ({product.amount} item)</h5>
                  <h5>R$ {maskMoney(Number(product.totalValue))}</h5>
                </div>
              ))
            ) : (
              <p></p>
            )}
            <div className={styles.valueDisplay}>
              <h1>Total:</h1>
              <h1>R$ {maskMoney(totalValue)}</h1>
            </div>
            <Spacing size="xxx-large" />
            <Row position="centered">
              <Button
                text="Ir para pagamento"
                width="190px"
                appearance={
                  context.products.length > 0 ? "primary" : "disabled"
                }
                onClick={() =>
                  context.products.length > 0 ? navigate("/cadastro") : ""
                }
              />
            </Row>
          </div>
        </Row>
      </div>
      <Spacing size="xxx-large" />
    </Layout>
  );
}

export default Summary;
