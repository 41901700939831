import React from "react";
import styles from "./styles.module.scss";

function Textarea({ name, placeholder, value }: any) {
  return (
    <div className={styles.field}>
      <textarea name={name} id={name} cols={30} rows={7}>
        {value}
      </textarea>
      <label htmlFor={name} className={`${value ? styles.labelValueOn : ""}`}>
        {placeholder}
      </label>
    </div>
  );
}

export default Textarea;
