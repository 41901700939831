import React from "react";
import styles from "./styles.module.scss";
import { FaRegCopy } from "react-icons/fa";
import {
  Card,
  Column,
  Description,
  Row,
  Text,
  Undertitle,
  Spacing,
  Button,
  Checkbox,
} from "..";
import qrcode from "../../assets/qrcode.png";
import logo from "../../assets/pix-logo.png";

function Pix({ check, onChangeCheckbox, onClick }) {
  return (
    <Card appearance="ghost" width="100%">
      <Row position="space-between">
        <Checkbox label="PIX" checked={check} onChange={onChangeCheckbox} />
        <img src={logo} alt="" style={{ width: "35px" }} />
      </Row>
      {check && (
        <>
          <Row>
            <Column>
              <Spacing size="medium" />
              <Description textAlign="start">
                1. Clique no botão "Gerar Pix";
              </Description>
              <Spacing size="xx-small" />
              <Description textAlign="start">
                2. Você será direcionado para a tela de pagamento por Pix;
              </Description>
              <Spacing size="xx-small" />
              <Description textAlign="start">
                3. Escolha a melhor fora de pagamento (QR code ou Link);
              </Description>
              <Spacing size="xx-small" />
              <Description textAlign="start">
                4. Após o pagamento você receberá um e-mail de confirmação e com
                detalhes do exame
              </Description>

              <Spacing size="large" />
              <Button
                text="Gerar PIX"
                appearance="primary"
                width="117px"
                onClick={onClick}
              />

              {/* <Spacing size="xx-small" /> */}
              {/* <Card appearance="ghost" width="100%">
            <div className={styles.copyCode}>
              <Text>00000000000000000000000</Text>
              <Text appearance="error" bolder={true} pointer={true}>
                Copiar <FaRegCopy className={styles.icon} />
              </Text>
            </div>
          </Card> */}
            </Column>

            {/* IMAGEM DO QR CODE */}
            {/* <Column position="centered">
              <Spacing size="medium" />
              <div className={styles.qrCode}>
                <img src={qrcode} />
              </div>
            </Column> */}
          </Row>
        </>
      )}
    </Card>
  );
}

export default Pix;
