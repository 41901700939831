import React from "react";
import styles from "./styles.module.scss";

function Undertitle({
  children,
  position,
  bolder = false,
  pointer = false,
  appearance = "default",
}: any) {
  return (
    <h2
      className={`${styles.text} 
    ${position === "start" && styles.start} 
    ${position === "centered" && styles.centered} 
    ${position === "end" && styles.end} 
    ${appearance === "default" && styles.default} 
    ${appearance === "primary" && styles.primary} 
    ${appearance === "primary-main" && styles.primaryMain} 
    ${appearance === "primary-light" && styles.primaryLight} 
    ${appearance === "primary-main" && styles.primaryMain} 
    ${appearance === "primary-intense" && styles.primaryIntense} 
    ${appearance === "complementary" && styles.complementary}
    ${appearance === "grey" && styles.grey} 
    ${appearance === "white" && styles.white} 
      `}
      style={{
        fontWeight: bolder ? "bolder" : "normal",
        cursor: pointer ? "pointer" : "default",
      }}
    >
      {children}
    </h2>
  );
}

export default Undertitle;
