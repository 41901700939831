import React from "react";
import styles from "./styles.module.scss";
import { BsXLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logos/portal-de-saude-logo.png";

function Navbar() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <img src={logo} alt="Logo" onClick={() => navigate("/")} />
      <BsXLg onClick={() => navigate("/")} />
    </div>
  );
}

export default Navbar;
