import React from "react";
import styles from "./styles.module.scss";
import Button from "../Button";

export type Props = {
  width?: string;
  labelLeft?: string;
  appearanceLeft?: "primary" | "complementary" | "ghost" | "disabled" | "success" | "ghost-success" | "warning" | "ghost-warning" | "error" | "ghost-error";
  actionLeft?: any;
  labelRight?: string;
  appearanceRight?: "primary" | "complementary" | "ghost" | "disabled" | "success" | "ghost-success" | "warning" | "ghost-warning" | "error" | "ghost-error";
  actionRight?: any;
  loadingLeft?: boolean,
  loadingRight?: boolean,
};

function BottomNavigation({
  width,
  labelLeft,
  appearanceLeft,
  actionLeft,
  loadingLeft = false,
  labelRight,
  appearanceRight,
  actionRight,
  loadingRight = false,
}: Props) {
  return (
    <div className={styles.container}>
      {labelLeft && (
        <div className={styles.leftButton}>
          <Button
            appearance={appearanceLeft}
            text={labelLeft}
            loading={loadingLeft}
            onClick={actionLeft}
            width={width}
          />
        </div>
      )}
      {labelRight && (
        <div className={styles.rightButton}>
          <Button
            appearance={appearanceRight}
            text={labelRight}
            loading={loadingRight}
            onClick={actionRight}
            width={width}
          />
        </div>
      )}
    </div>
  );
}

export default BottomNavigation;
