import React, { createContext, useEffect, useState } from "react";

export const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [context, setContext] = useState({
    location: {
      postCode: "",
      city: "",
    },
    packages: [],
    products: [],
    info: {
      purchase_id: 0,
    },
  });

  function addProduct(data: any) {
    const ifExists = context.products.some((product) => product.id === data.id);
    if (ifExists) {
      const item = context.products.find((product) => product.id === data.id);
      item.amount = item.amount + 1;
      const index = context.products.findIndex(
        (product) => product.id === data.id
      );
      context.products[index] = item;
      setContext({ ...context });
    } else {
      // eslint-disable-next-line no-param-reassign
      data.amount = 1;
      context.products.push(data);
    }
    setContext({ ...context });
    localStorage.setItem("@data", JSON.stringify(context));
  }

  function deleteProduct(id: number) {
    const index = context.products.findIndex((element) => element.id === id);
    context.products.splice(index, 1);
    setContext({ ...context });
    localStorage.setItem("@data", JSON.stringify({ ...context }));
  }

  function handleData(data: any) {
    setContext({ ...context, ...data });
    localStorage.clear();
    localStorage.setItem("@data", JSON.stringify({ ...context, ...data }));
  }

  function updatePackages(data: any) {
    setContext({ ...context, packages: [...data] });
    localStorage.clear();
    localStorage.setItem(
      "@data",
      JSON.stringify({ ...context, packages: [...data] })
    );
  }

  function updateLocation(data: any) {
    setContext({ ...context, location: { ...data } });
    localStorage.clear();
    localStorage.setItem(
      "@data",
      JSON.stringify({ ...context, location: { ...data } })
    );
  }

  function updateOrderID(data: any) {
    setContext({ ...context, info: { ...data } });
    localStorage.clear();
    localStorage.setItem(
      "@data",
      JSON.stringify({ ...context, info: { ...data } })
    );
  }

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("@data"));
    if (storage && storage.products.length > 0) {
      setContext({ ...storage });
      localStorage.setItem("@data", JSON.stringify(storage));
    }
  }, []);

  return (
    <DataContext.Provider
      value={{
        context,
        setContext,
        handleData,
        updateLocation,
        addProduct,
        updatePackages,
        updateOrderID,
        deleteProduct,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
